import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default-page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Weber `}<em parentName="h1">{`(Saint-Gobain)`}</em></h1>
    <p>{`Weber (Saint-Gobain) are a european mortar manufacturer offering a complete range of solutions developed using advanced formulation technology to meet the highest technical requirements.`}</p>
    <p>{`Formulating and manufacturing building materials, Weber’s well established product range includes monocouche (one coat) renders, external wall insulation systems, tile adhesives/grouts, levelling compounds and specialist construction products.`}</p>
    <p>{`While profiting from an international presence in 45 countries, Weber maintain a de-centralised team structure which focuses on local specification and the local market condition.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      